var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{class:{ 'pr-0 pl-0': !_vm.$vuetify.breakpoint.mdAndUp }},[_c('v-card',{staticClass:"card_background"},[_c('v-expand-transition',{attrs:{"leave-absolute":""}},[_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loader),expression:"!loader"}],staticClass:"px-5 pt-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('h3',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.branchOffice.branch_office_name)+" "),(_vm.defaultBranchOffice)?_c('span',[_c('v-chip',{attrs:{"small":""}},[_vm._v("Predeterminada")])],1):_vm._e()]),_c('p',{staticClass:"caption mb-1 mt-1"},[_vm._v(" Esta sucursal tienen el inventario e información independiente de las demás ")])]),_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"12","lg":"8","xl":"8"}},[_c('div',{staticClass:"d-flex align-start justify-space-between"},[_c('v-checkbox',{staticClass:"ma-0 caption",attrs:{"disabled":_vm.defaultBranchOffice,"dense":""},on:{"change":function($event){_vm.wereChangesDetected = true;
                  _vm.statusChangeDefaultBranch = true;}},model:{value:(_vm.checkDefaultBranchOffice),callback:function ($$v) {_vm.checkDefaultBranchOffice=$$v},expression:"checkDefaultBranchOffice"}},[_c('span',{staticClass:"caption",attrs:{"slot":"label"},slot:"label"},[_vm._v("Marcar como predeterminada")])]),_c('v-tooltip',{attrs:{"bottom":"","max-width":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mt-1",attrs:{"color":"grey","size":"19","dark":""}},'v-icon',attrs,false),on),[_vm._v(" help ")])]}}])},[_c('span',[_vm._v("Selecciona esta sucursal como predeterminada para filtrar los resultados dentro de la aplicación")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"name",attrs:{"disabled":!_vm.isAdminUser,"outlined":"","type":"text","rules":[_vm.requiredRules],"hide-details":"","label":"Nombre de la sucursal"},on:{"input":function($event){_vm.wereChangesDetected = true;
                    _vm.metaEditBranch = true;}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.mail.focus()}},model:{value:(_vm.branchOffice.branch_office_name),callback:function ($$v) {_vm.$set(_vm.branchOffice, "branch_office_name", $$v)},expression:"branchOffice.branch_office_name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"caption ma-0"},[_vm._v("Ubicación de la sucursal")]),_c('v-text-field',{attrs:{"id":"ship-address","outlined":"","hide-details":"","autocomplete":"off"},on:{"input":function($event){_vm.wereChangesDetected = true;
                    _vm.metaEditBranch = true;}},model:{value:(_vm.branchOffice.location.full_address),callback:function ($$v) {_vm.$set(_vm.branchOffice.location, "full_address", $$v)},expression:"branchOffice.location.full_address"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.branchOffice.location.full_address),expression:"branchOffice.location.full_address"}]},[_c('v-col',{attrs:{"cols":"12"}},[_c('AppMapDraggableMarker',{attrs:{"myLatlng":_vm.branchOffice.location.map_point}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"street",attrs:{"disabled":!_vm.isAdminUser,"outlined":"","type":"text","rules":[_vm.requiredRules],"label":"Calle","hide-details":""},on:{"input":function($event){_vm.wereChangesDetected = true;
                        _vm.metaEditBranch = true;}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.reference.focus()}},model:{value:(_vm.branchOffice.location.street_name),callback:function ($$v) {_vm.$set(_vm.branchOffice.location, "street_name", $$v)},expression:"branchOffice.location.street_name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"reference",attrs:{"disabled":!_vm.isAdminUser,"outlined":"","type":"text","rules":[_vm.requiredRules],"label":"Referencias","hide-details":""},on:{"input":function($event){_vm.wereChangesDetected = true;
                        _vm.metaEditBranch = true;}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.streetNumber.focus()}},model:{value:(_vm.branchOffice.location.reference),callback:function ($$v) {_vm.$set(_vm.branchOffice.location, "reference", $$v)},expression:"branchOffice.location.reference"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('v-text-field',{ref:"streetNumber",attrs:{"disabled":!_vm.isAdminUser,"outlined":"","type":"number","rules":[_vm.requiredRules],"label":"Numero Exterior","hide-details":""},on:{"input":function($event){_vm.wereChangesDetected = true;
                        _vm.metaEditBranch = true;}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.postalCode.focus()}},model:{value:(_vm.branchOffice.location.street_number),callback:function ($$v) {_vm.$set(_vm.branchOffice.location, "street_number", $$v)},expression:"branchOffice.location.street_number"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('v-text-field',{ref:"postalCode",attrs:{"disabled":!_vm.isAdminUser,"outlined":"","type":"number","rules":[_vm.requiredRules],"label":"Código postal","hide-details":""},on:{"input":function($event){_vm.wereChangesDetected = true;
                        _vm.metaEditBranch = true;}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.city.focus()}},model:{value:(_vm.branchOffice.location.postal_code),callback:function ($$v) {_vm.$set(_vm.branchOffice.location, "postal_code", $$v)},expression:"branchOffice.location.postal_code"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('v-text-field',{ref:"city",attrs:{"disabled":!_vm.isAdminUser,"outlined":"","type":"text","rules":[_vm.requiredRules],"label":"Ciudad","hide-details":""},on:{"input":function($event){_vm.wereChangesDetected = true;
                        _vm.metaEditBranch = true;}},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$refs.state.focus()}},model:{value:(_vm.branchOffice.location.city_name),callback:function ($$v) {_vm.$set(_vm.branchOffice.location, "city_name", $$v)},expression:"branchOffice.location.city_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12","lg":"6","xl":"6"}},[_c('v-select',{ref:"state",attrs:{"disabled":!_vm.isAdminUser,"rules":[_vm.requiredRules],"items":_vm.getStates,"label":"Estado","outlined":"","hide-details":""},on:{"input":function($event){_vm.wereChangesDetected = true;
                        _vm.metaEditBranch = true;}},model:{value:(_vm.branchOffice.location.state_name),callback:function ($$v) {_vm.$set(_vm.branchOffice.location, "state_name", $$v)},expression:"branchOffice.location.state_name"}})],1)],1)],1)],1)],1)],1)],1)],1),_c('v-container',[(_vm.loader)?_c('v-skeleton-loader',{attrs:{"type":"card-heading, list-item-three-line, list-item-two-line, list-item-three-line, list-item-two-line, list-item-three-line"}}):_vm._e()],1)],1),_c('v-scroll-y-reverse-transition',{attrs:{"leave-absolute":""}},[(_vm.wereChangesDetected)?_c('app-save-changes',{attrs:{"saveChanges":_vm.saveChanges,"discardChanges":_vm.discardChanges}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }