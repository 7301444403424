<template>
  <v-container :class="{ 'pr-0 pl-0': !$vuetify.breakpoint.mdAndUp }">
    <v-card class="card_background">
      <v-expand-transition leave-absolute>
        <v-container v-show="!loader" class="px-5 pt-0">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="4" xl="4">
              <h3 class="text-h6">
                {{ branchOffice.branch_office_name }}
                <span v-if="defaultBranchOffice"
                  ><v-chip small>Predeterminada</v-chip></span
                >
              </h3>
              <p class="caption mb-1 mt-1">
                Esta sucursal tienen el inventario e información independiente
                de las demás
              </p>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="8" xl="8" class="pb-0">
              <div class="d-flex align-start justify-space-between">
                <v-checkbox
                  :disabled="defaultBranchOffice"
                  class="ma-0 caption"
                  dense
                  v-model="checkDefaultBranchOffice"
                  @change="
                    wereChangesDetected = true;
                    statusChangeDefaultBranch = true;
                  "
                  ><span slot="label" class="caption"
                    >Marcar como predeterminada</span
                  ></v-checkbox
                >
                <v-tooltip bottom max-width="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="grey"
                      size="19"
                      class="mt-1"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      help
                    </v-icon>
                  </template>
                  <span
                    >Selecciona esta sucursal como predeterminada para filtrar
                    los resultados dentro de la aplicación</span
                  >
                </v-tooltip>
              </div>

              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :disabled="!isAdminUser"
                    outlined
                    type="text"
                    ref="name"
                    v-model="branchOffice.branch_office_name"
                    :rules="[requiredRules]"
                    hide-details
                    label="Nombre de la sucursal"
                    @input="
                      wereChangesDetected = true;
                      metaEditBranch = true;
                    "
                    @keyup.enter.native="$refs.mail.focus()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <p class="caption ma-0">Ubicación de la sucursal</p>
                  <v-text-field
                    id="ship-address"
                    outlined
                    hide-details
                    autocomplete="off"
                    @input="
                      wereChangesDetected = true;
                      metaEditBranch = true;
                    "
                    v-model="branchOffice.location.full_address"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-row v-show="branchOffice.location.full_address">
                    <v-col cols="12">
                      <AppMapDraggableMarker
                        :myLatlng="branchOffice.location.map_point"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :disabled="!isAdminUser"
                        outlined
                        type="text"
                        ref="street"
                        v-model="branchOffice.location.street_name"
                        :rules="[requiredRules]"
                        label="Calle"
                        @input="
                          wereChangesDetected = true;
                          metaEditBranch = true;
                        "
                        @keyup.enter.native="$refs.reference.focus()"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :disabled="!isAdminUser"
                        outlined
                        type="text"
                        ref="reference"
                        v-model="branchOffice.location.reference"
                        :rules="[requiredRules]"
                        label="Referencias"
                        @input="
                          wereChangesDetected = true;
                          metaEditBranch = true;
                        "
                        @keyup.enter.native="$refs.streetNumber.focus()"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                      <v-text-field
                        :disabled="!isAdminUser"
                        outlined
                        type="number"
                        ref="streetNumber"
                        v-model="branchOffice.location.street_number"
                        :rules="[requiredRules]"
                        label="Numero Exterior"
                        hide-details
                        @input="
                          wereChangesDetected = true;
                          metaEditBranch = true;
                        "
                        @keyup.enter.native="$refs.postalCode.focus()"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                      <v-text-field
                        :disabled="!isAdminUser"
                        outlined
                        type="number"
                        ref="postalCode"
                        v-model="branchOffice.location.postal_code"
                        :rules="[requiredRules]"
                        label="Código postal"
                        @input="
                          wereChangesDetected = true;
                          metaEditBranch = true;
                        "
                        @keyup.enter.native="$refs.city.focus()"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                      <v-text-field
                        :disabled="!isAdminUser"
                        outlined
                        type="text"
                        ref="city"
                        v-model="branchOffice.location.city_name"
                        :rules="[requiredRules]"
                        label="Ciudad"
                        @input="
                          wereChangesDetected = true;
                          metaEditBranch = true;
                        "
                        @keyup.enter.native="$refs.state.focus()"
                        hide-details
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="6" xl="6">
                      <v-select
                        :disabled="!isAdminUser"
                        v-model="branchOffice.location.state_name"
                        :rules="[requiredRules]"
                        ref="state"
                        :items="getStates"
                        label="Estado"
                        outlined
                        hide-details
                        @input="
                          wereChangesDetected = true;
                          metaEditBranch = true;
                        "
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-expand-transition>
      <v-container>
        <v-skeleton-loader
          v-if="loader"
          type="card-heading, list-item-three-line, list-item-two-line, list-item-three-line, list-item-two-line, list-item-three-line"
        ></v-skeleton-loader>
      </v-container>
    </v-card>
    <v-scroll-y-reverse-transition leave-absolute>
      <app-save-changes
        v-if="wereChangesDetected"
        :saveChanges="saveChanges"
        :discardChanges="discardChanges"
      ></app-save-changes>
    </v-scroll-y-reverse-transition>
  </v-container>
</template>

<script>
import { required } from "@/services/ValidatorService";
import SaveChanges from "@/components/app/SaveChanges";
import { mapActions, mapGetters, mapState } from "vuex";
import { Loader } from "@googlemaps/js-api-loader";
import { app } from "@/config/globals";
import MapDraggableMarker from "@/components/app/MapDraggableMarker";
let autocomplete;

export default {
  data() {
    return {
      requiredRules: required,
      branchOffice: {
        location: {}
      },
      loader: true,
      checkDefaultBranchOffice: false,
      statusChangeDefaultBranch: false,
      metaEditBranch: false,
      wereChangesDetected: false
    };
  },
  computed: {
    ...mapState("business", ["currentBranchID", "businessLocationLatLng"]),
    ...mapGetters("user", ["isAdminUser"]),
    ...mapGetters("app", ["getStates"]),
    defaultBranchOffice() {
      return this.currentBranchID === this.branchID ? true : false;
    }
  },
  beforeMount() {
    this.checkDefaultBranchOffice = this.defaultBranchOffice;
    this.getSingleBranchOfficeData(this.branchID).then(branchOffice => {
      this.branchOffice = branchOffice;
      this.loader = false;
      this.initMapLoader();
    });
  },
  watch: {
    businessLocationLatLng(newLatLng) {
      if (newLatLng) {
        this.branchOffice.location.map_point = newLatLng;
        this.wereChangesDetected = true;
        this.metaEditBranch = true;
      }
    }
  },
  methods: {
    ...mapActions("business", [
      "getSingleBranchOfficeData",
      "updateBranchOfficeData",
      "changeCurrentBranch"
    ]),
    discardChanges() {
      this.loader = false;
      this.getSingleBranchOfficeData(this.branchID).then(branchOffice => {
        this.branchOffice = branchOffice;
        this.loader = false;
      });
      this.wereChangesDetected = false;
    },
    saveChanges() {
      if (this.metaEditBranch) this.updateBranchOfficeData(this.branchOffice);
      if (this.statusChangeDefaultBranch) {
        this.changeCurrentBranch(this.branchOffice.id);
      }
      this.$router.go(-1);
      this.wereChangesDetected = false;
    },
    // MAPS
    async initMapLoader() {
      const fieldContainer = document.querySelector("#ship-address");
      const loader = new Loader({
        apiKey: app.maps_api_key,
        version: "weekly",
        libraries: ["places"]
      });
      // eslint-disable-next-line
      if (typeof google === "object" && typeof google.maps === "object") {
        // eslint-disable-next-line
        autocomplete = new google.maps.places.Autocomplete(fieldContainer, {
          componentRestrictions: { country: ["mx"] },
          fields: ["address_components", "geometry"],
          types: ["address"]
        });
      } else {
        await loader.load();
        // eslint-disable-next-line
        autocomplete = new google.maps.places.Autocomplete(fieldContainer, {
          componentRestrictions: { country: ["mx"] },
          fields: ["address_components", "geometry"],
          types: ["address"]
        });
      }
      autocomplete.addListener("place_changed", this.fillInAddress);
    },
    fillInAddress() {
      // Get the place details from the autocomplete object.
      const place = autocomplete.getPlace();
      this.branchOffice.location.full_address = "";
      for (const placeAddress of place.address_components) {
        const placeType = placeAddress.types[0];
        switch (placeType) {
          case "route": {
            this.branchOffice.location.street_name = placeAddress.long_name;
            this.branchOffice.location.full_address +=
              placeAddress.long_name + ", ";
            break;
          }
          case "postal_code": {
            this.branchOffice.location.postal_code = placeAddress.long_name;
            break;
          }
          case "sublocality_level_1": {
            this.branchOffice.location.full_address +=
              placeAddress.long_name + ", ";
            break;
          }
          case "locality": {
            this.branchOffice.location.city_name = placeAddress.long_name;
            this.branchOffice.location.full_address +=
              placeAddress.short_name + ", ";
            break;
          }
          case "administrative_area_level_1": {
            this.branchOffice.location.state_name = placeAddress.long_name;
            this.branchOffice.location.full_address += placeAddress.short_name;
            break;
          }
        }
      }
      const lat = autocomplete.getPlace().geometry.location.lat();
      const lng = autocomplete.getPlace().geometry.location.lng();
      this.branchOffice.location.map_point = { lat, lng };
    }
  },
  components: {
    AppSaveChanges: SaveChanges,
    AppMapDraggableMarker: MapDraggableMarker
  },
  props: {
    branchID: {
      type: String,
      required: true
    }
  }
};
</script>
